@use 'sass:color';

@media (prefers-color-scheme: dark) {
    body {
        color: $text-color-dark;
    }

    .post-meta ul li {
        .meta-text {
            color: $grey-color-dark;
        }

        .meta-icon svg {
            fill: $grey-color-dark;
        }
    }

    body,
    .page-content,
    {
        background-color: $background-color-dark;
    }

    body > header,
    body > footer,
    article div.series-header,
    article div.series-footer,
    .pagination,
    .post-list > li,
    .site-nav,
    .section-nav,
    .post-nav,
    .post-content tbody tr:nth-child(odd) {
        background-color: color.adjust($background-color-dark, $lightness: 5%, $space: hsl);
    }

    .post-list > li article a.read-more {
        background: color.adjust($background-color-dark, $lightness: 5%, $space: hsl);
        box-shadow: 0 -12px 50px 50px color.adjust($background-color-dark, $lightness: 5%, $space: hsl);
    }

    .post-content {
        td, th {
            border-bottom-color: $brand-color-dark;
        }

        q {
            background-color: color.adjust($background-color-dark, $lightness: 10%, $space: hsl);
        }
    }

    .pagination,
    .post-nav,
    .section-nav {
        .button {
            border-color: $grey-color-dark;

            &.disabled {
                color: $grey-color-dark;
            }
        }
    }

    .highlight,
    pre,
    code {
        background-color: color.adjust($background-color-dark, $lightness: 10%, $space: hsl);
        border-color: $grey-color-dark;
    }

    a {
        color: $brand-color-dark;

        &:visited {
            color: color.adjust($brand-color-dark, $hue: -120deg, $space: hsl);
        }

        &:hover {
            color: $text-color-dark;
        }
    }
}
