@use 'sass:color';

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}


/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: calc($spacing-unit / 2);
}


/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

article {
    img, iframe {
        border-radius: 4px;
        @include BoxShadow(1);

        &.center {

        }

        &.width-75 {
            max-width: 75%;
        }

        &.width-50 {
            max-width: 50%;
        }
    }

    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        margin-bottom: 1em;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        clear: both;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    p.center {
        clear: both;
        text-align: center;

        img {
            float: none;
        }
    }

    p.fill {
        text-align: center;

        img {
            float: none;
            width: 90%;
        }
    }

    p.small,
    div.footnotes {
        font-size: 0.75em;
    }
}

video {
    width: 100%;
    vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
    display: block;
    float: none;
}

figcaption {
    font-size: $small-font-size;
}


/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}


/**
 * Links
 */
a {
    color: $brand-color;

    &:visited {
        color: color.adjust($brand-color, $hue: +120deg, $space: hsl);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}


/**
 * Blockquotes
 */
blockquote {
    border-left: calc($spacing-unit / 8) solid $grey-color;
    padding-left: calc($spacing-unit / 2);
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}


/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}


/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width: calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: calc($spacing-unit / 2);
        padding-left: calc($spacing-unit / 2);
    }
}


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}
