@use 'sass:color';

/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    min-height: 56px;
    z-index: 100;

    // Positioning context for the mobile navigation icon
    position: relative;
    @include BoxShadow(8);
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}


.category-index,
.tag-index,
.home {
    padding: $spacing-unit 0;

    ul.post-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        column-gap: $spacing-unit;
        row-gap: $spacing-unit;

        & > li {
            flex-grow: 1;
            //width: 300px;
            height: 350px;
            overflow: hidden;

            article {
                display: flex;
                flex-direction: column;
                justify-items: stretch;
                height: 100%;

                div.post-content {
                    flex-shrink: 1;
                    flex-grow: 1;
                    overflow: hidden;
                }
            }
        }
    }
}


/**
 * Site footer
 */
.site-footer {
    padding: $spacing-unit 0;
    @include BoxShadow(8);
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-wide {
    text-align: center;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: calc(-1 * $spacing-unit / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(30% - (#{$spacing-unit} / 2));
    width:         calc(30% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(30% - (#{$spacing-unit} / 2));
    width:         calc(30% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    background-color: #f6f6f6;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        background-color: white;
        padding: $spacing-unit;
        border-radius: 4px;
        @include BoxShadow(1);

        article >:last-child {
            clear: both;
        }

        a.read-more {
            background: $background-color;
            box-shadow: 0 -12px 50px 50px $background-color;
            font-weight: 500;
            padding-top: 8px;
            text-transform: uppercase;
            z-index: 100;
        }
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;

        li {
            display: flex;
            flex-shrink: 0;
            flex-wrap: nowrap;
            align-items: center;

            .meta-icon {
                line-height: 0.8;

                svg {
                    fill: $grey-color;
                    height: 24px;
                }
            }

            .meta-text {
                margin: 4px;
            }
        }
    }
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    h1, h2, h3, h4 { clear: both; }

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    q {
        font-style: italic;
        background-color: color.adjust($background-color, $lightness: -10%, $space: hsl);
        quotes: "«" "»" "‹" "›";

        &:before {
            content: open-quote;
        }

        &:after {
            content: close-quote;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: $spacing-unit;
    }

    th, td {
        text-align: left;
        padding: calc($spacing-unit / 2);
        border-bottom: thin solid $brand-color;
    }

    tbody tr:nth-child(odd) {
        background-color: color.adjust($background-color, $lightness: -5%, $space: hsl);
    }
}

.pagination,
.post-nav,
.section-nav {
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: white;
    padding: $spacing-unit;
    margin: $spacing-unit 0;
    border-radius: 4px;
    @include BoxShadow(1);

    .button {
        display: flex;
        align-items: center;

        min-width: 64px;
        height: 36px;
        padding: 0 16px 0 12px;

        font-size: 14px;
        font-weight: bold;

        text-decoration: none;
        text-transform: uppercase;

        border-radius: 4px;
        border: thin solid $grey-color-light;

        &.disabled {
            color: $grey-color-light;
        }

        &.previous {
            padding: 0 16px 0 12px;

            .material-icons {
                padding-right: 8px;
            }
        }

        &.next {
            padding: 0 12px 0 16px;

            .material-icons {
                padding-left: 8px;
            }
        }
    }
}

.post-nav + h3 {
  clear: both;
}

.post-nav>div,
.section-nav>div {
  width: 33.3%;
  display: inline-block;
}

a.button,
span.button,
.post-nav>div>span,
.post-nav>div>a,
.section-nav>div>span,
.section-nav>div>a {
  border-radius: 5pt;
  border: thin solid $grey-color-light;
  padding: 5pt 15pt;
}

a.button:hover,
.pagination a:hover,
.post-nav a:hover,
.section-nav a:hover {
  background: lightgray;
  text-decoration: none;
}

.align-right { text-align: right; }
.align-left { text-align: left; }

.left { float: left; margin-right: 1em }
.right { float: right; margin-left: 1em; }

div.post.candid-strategies-md
article
ul
{
  margin: 0;
}

div.post.candid-strategies-md
article
ul
li
{
  display: block;
  border: thin solid $grey-color-light;
  border-radius: 1em;
  padding: 6em;
  width: 15em;
  margin: 2em auto;
  text-align: center;
}

article iframe,
ul.post-list iframe
{
  max-width: 100%;
}

article div.comment
{
  border: thin dotted grey;
  padding: 1em;
  margin: 1em 0;
}

article div.series-header,
article div.series-footer
{
    background-color: white;
    padding: $spacing-unit;
    margin: $spacing-unit 0;
    border-radius: 4px;
    @include BoxShadow(1);
    clear: both;

    :last-child {
        margin-bottom: 0;
    }
}

.yearly-archive dl h2 {
    margin-top: 0.75em;
}

.archives ul
{
    display: grid;
    margin: 0;
    list-style: none;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2em;

    li
    {
        margin-bottom: 1em;
        border-bottom: thin solid $grey-color-light;

        a
        {
            display: flex;
            justify-content: space-between;
        }
    }
}

.yearly-archive dl,
.monthly-archive dl
{
    display: grid;
    margin: 0;
    list-style: none;
    grid-template-columns: 12em 1fr;
    margin-bottom: 1em;
}

.page-content .post {
    .lead-image {
        position: relative;
        top: -1.5em;
        display: flex;
        flex-direction: column;
        background-size: cover;
        background-position: 50%;
        background-color: #707070;
        background-blend-mode: color-dodge;
        box-shadow: inset 0 -8px 10px 1px rgba(0, 0, 0, .14),
            inset 0 -3px 14px 2px rgba(0, 0, 0, .12),
            inset 0 -5px 5px -3px rgba(0, 0, 0, .20);

        header {
            text-align: center;
            align-self: center;
            padding-top: 100px;
            padding-bottom: 60px;
            color: black;
            max-width: 90vw;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 4px;

            .post-title {
                font-size: 7vw;
                margin-bottom: 0;
            }

            ul {
                justify-content: center;
                gap: 16px;

                li {
                    background-color: rgba(255, 255, 255, 0.9);
                    border-radius: 4px;
                    padding: 4px;
                    align-items: center;
                }
            }
        }
    }

    .lead-image-credit {
        text-align: center;
        font-style: italic;
        color: $grey-color;
    }
}
