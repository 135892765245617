@use 'sass:color';

#disqus_thread,
.webmentions {
    background-color: white;
    padding: $spacing-unit;
    margin: $spacing-unit 0;
    border-radius: 4px;
    @include BoxShadow(1);
}

.webmentions .webmention {
    border: thin solid $grey-color-light;
    padding: $spacing-unit;
    margin-top: $spacing-unit;
    border-radius: 4px;
    @include BoxShadow(1);
}

@media (prefers-color-scheme: dark) {
    #disqus_thread,
    .webmentions {
        background-color: color.adjust($background-color-dark, $lightness: 5%, $space: hsl);
    }

    .webmentions .webmention {
        background-color: color.adjust($background-color-dark, $lightness: 10%, $space: hsl);
        border: none;
    }

    article img {
        filter: brightness(0.8) contrast(1.2);
    }
}

.blogroll,
.full-archive {
    dl {
        h2 {
            margin-top: 0.75em;
        }

        display: grid;
        margin: 0;
        list-style: none;
        grid-template-columns: 8em 1fr;
        margin-bottom: 1em;
    }
}

article {
    .no-box-shadow {
        box-shadow: none;
    }

    abbr {
        text-decoration: underline;
        text-decoration-style: dotted;
    }

    img.reflect {
        border-radius: 3pt;
        -webkit-box-reflect: below 7pt -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(transparent),
                        color-stop(75%, transparent),
                        to(rgba(250, 250, 250, 0.2)));
        margin-bottom: 2em;
    }

    img.width-40, iframe.width-40 {
        max-width: 40%;
    }

    @media screen and (min-width: $on-laptop + 128) {
        img.left {
            margin-right: 1em;
            margin-left: -4em;
        }

        img.right {
            margin-right: -4em;
            margin-left: 1em;
        }
    }

    @media screen and (max-width: $on-laptop + 128) {
        img.left,
        img.right {
            width: 100%;
            float: none;
        }
    }

    table.width-50 {
        width: 50%;
    }
}

.sl-wrapper {
    .sl-image img {
        border-radius: 4px;
    }
}

@media (prefers-color-scheme: dark) {
    .highlight {
        .nt {
            color: #6060bf;
        }

        .s, .nf {
            color: #de5778;
        }
    }

    .sl-overlay {
        background: #121212 !important;
    }

    .sl-wrapper {
        .sl-navigation button,
        .sl-counter,
        .sl-close {
            color: #888 !important;
        }
    }

    .category-index ul.post-list > li article a.read-more,
    .tag-index ul.post-list > li article a.read-more,
    .home ul.post-list > li article a.read-more {
        z-index: 100;
    }
}
