
img.askew_left {
  transform: matrix3d(1, 0, 0, 0.0006, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  border: red 2px solid;
  width: calc($content-width / 4);
}

img.reflect,
img.askew_left
{
  border-radius: 3pt;
  -webkit-box-reflect: below 7pt -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, transparent), to(rgba(250, 250, 250, 0.3)));
}

img.small {
  width: 15em;
}
