
.youtube-embedder {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 1em 0;
}

.youtube-embedder iframe,
.youtube-embedder object,
.youtube-embedder embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
